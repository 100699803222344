* {
    box-sizing: border-box;
}

.app {
    text-align: center;
}

.preview {
    width: 512px;
    display: block;
    margin: auto;
}

.items {
    display: flex;
    flex-wrap: wrap;
}

.item {
    width: 148px;
    height: 116px;
    border: 1px solid silver;
    margin: 10px;
}

.thumb {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.addButton {
    composes: item;
}
